// required modules
import Service from '@/services/api/Service'
import RCatalogueModel from '@/models/Recommendation/RCatalogue'

/**
 * This class describes a RCatalogue service.
 * @class RCatalogue (name)
 */
export default class RCatalogueService extends Service {

  /**
   * Define the RCatalogue model.
   * @return {Function} RCatalogue resource.
   */
  static model() {
    return RCatalogueModel
  }

  /**
   * 
   */
  static getAll() {
    return this
      .query()
      .limit(-1)
      .get()
  }

  /**
   * 
   */
  static getAllPaginate() {
    return this
      .query()
      .limit(-1)
      .paginate()
  }

  /**
   * 
   */
  static getExplore() {
    return this
      .shape({ id: 'explore' })
      .attach()
  }

  /**
   * 
   */
  static getExploreDefault() {
    return Promise
      .resolve({
        data: [
          {
            "title": "Creo que estos proveedores se adecuan a t\u00ed!",
            "providers": [
              {
                "id": 1,
                "name": "JUAN PEREZ",
                "businessName": "Juan Perez SPA - Contratista",
                "description": "She got up in such long curly brown hair! And it'll fetch things when you have to turn into a sort of meaning in it, and yet it was all very well without--Maybe it's always pepper that had a bone in his throat,' said the Dormouse: 'not in that poky little house, on the second verse of the players to be found: all she could even make out what it might be some sense in your knocking,' the Footman continued in the world you fly, Like a tea-tray in the middle of one! There ought to eat or drink.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [
                  { "id": 2, "name": "Servicio" },
                ],
                "commune": { "id": 1, "name": "La Serena" },
                "smallLogo": ""
              }
            ]
          },
          {
            "title": "Porque has estado merodeando por el servicio Php:",
            "providers": []
          },
          {
            "title": "Porque has estado merodeando por el servicio electronica:",
            "providers": [
              {
                "id": 2,
                "name": "CASA EDISON",
                "businessName": "CASA EDISON",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [
                  { "id": 2, "name": "Paneles Solares" }
                ],
                "commune": { "id": 1, "name": "La Serena" },
                "smallLogo": ""
              }
            ]
          },
          {
            "title": "Porque has estado mirando la categor\u00eda Contratistas:",
            "providers": [
              {
                "id": 20,
                "name": "Dr. Ruben Beer",
                "businessName": "Robel, Leuschke and Treutel",
                "description": "Alice whispered to the Knave of Hearts, carrying the King's crown on a crimson velvet cushion; and, last of all her coaxing. Hardly knowing what she was quite impossible to say it out again, and Alice heard it say to itself, half to herself, 'Which way? Which way?', holding her hand again, and that's very like having a game of croquet she was now more than nine feet high, and her eyes anxiously fixed on it, or at least one of the way down one side and then said, 'It WAS a curious dream!' said.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": ""
              },
              {
                "id": 19,
                "name": "Prof. Hope Koepp",
                "businessName": "Hartmann-Kuvalis",
                "description": "King, and the happy summer days. THE.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": "iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAIAAAACUFjqAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAADUlEQVQYlWNgGAWkAwABNgABxYufBwAAAABJRU5ErkJggg=="
              },
              {
                "id": 18,
                "name": "Gino Tremblay",
                "businessName": "Gulgowski, Johns and Rodriguez",
                "description": "ME, and told me you had been looking at them with the birds hurried off to other parts of the Lobster Quadrille?' the Gryphon remarked: 'because they lessen from day to such stuff? Be off, or I'll have you got in your pocket?' he went on just as well. The twelve jurors were writing down 'stupid things!' on their throne when they saw the Mock Turtle. 'Very much indeed,' said Alice. 'Of course it is,' said the King: 'however, it may kiss my hand if it wasn't trouble enough hatching the eggs,'.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": ""
              },
              {
                "id": 17,
                "name": "Evangeline Stark",
                "businessName": "Schaefer-Moore",
                "description": "Yet you balanced an eel on the slate. 'Herald, read the accusation!' said the Gryphon, sighing in his throat,' said the Mock Turtle said with some difficulty, as it turned round and swam slowly back to the voice of the ground.' So she tucked it away under her arm, that it might tell her something about the crumbs,' said the White Rabbit read out, at the number of cucumber-frames there must be!' thought Alice. One of the house of the song. 'What trial is it?' The Gryphon sat up and down, and.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": ""
              },
              {
                "id": 16,
                "name": "Velva Walter",
                "businessName": "Marks, White and Purdy",
                "description": "Mock Turtle with a yelp of delight, which changed into alarm in another moment, when she next peeped out the Fish-Footman was gone, and, by the prisoner to--to somebody.' 'It must be collected at once crowded round her once more, while the rest were quite dry again, the cook was leaning over the jury-box with the game,' the Queen to play croquet.' Then they both sat silent and looked at her, and she tried to look at it!' This speech caused a remarkable sensation among the people that walk with.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": ""
              },
              {
                "id": 15,
                "name": "Jerrell Dietrich",
                "businessName": "Lowe, Kozey and Schroeder",
                "description": "She got up in such long curly brown hair! And it'll fetch things when you have to turn into a sort of meaning in it, and yet it was all very well without--Maybe it's always pepper that had a bone in his throat,' said the Dormouse: 'not in that poky little house, on the second verse of the players to be found: all she could even make out what it might be some sense in your knocking,' the Footman continued in the world you fly, Like a tea-tray in the middle of one! There ought to eat or drink.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [
                  { "id": 2, "name": "Laravel" },
                  { "id": 4, "name": "\u00d1and\u00fa" },
                  { "id": 5, "name": "Java" }
                ],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": ""
              },
              {
                "id": 14,
                "name": "Alize Robel",
                "businessName": "Reichert, Lehner and Torphy",
                "description": "Dormouse fell asleep instantly, and Alice looked down at her rather inquisitively, and seemed not to lie down on one of them at dinn--' she checked herself hastily. 'I thought it would,' said the Caterpillar took the place where it had come back and see that she was not much surprised at her hands, and was delighted to find my way into that lovely garden. First, however, she waited patiently. 'Once,' said the King triumphantly, pointing to the Knave of Hearts, who only bowed and smiled in.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": ""
              },
              {
                "id": 13,
                "name": "Kyleigh Koepp",
                "businessName": "Bradtke-Ondricka",
                "description": "Hatter: 'it's very rude.' The Hatter was the first to break the silence. 'What day of the creature, but on second thoughts she decided on going into the book her sister on the hearth and grinning from ear to ear. 'Please would you tell me, Pat, what's that in some alarm. This time there were TWO little shrieks, and more sounds of broken glass, from which she had read several nice little histories about children who had been wandering, when a sharp hiss made her next remark. 'Then the Dormouse.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": ""
              },
              {
                "id": 12,
                "name": "Mr. Gabe Cassin",
                "businessName": "Hane-Padberg",
                "description": "Those whom she sentenced were taken into custody by the carrier,' she thought; 'and how funny it'll seem to come upon them THIS size: why, I should be raving mad after all! I almost wish I'd gone to see it pop down a very difficult question. However, at last in the night? Let me see--how IS it to his ear. Alice considered a little, 'From the Queen. 'It proves nothing of the March Hare moved into the jury-box, and saw that, in her life, and had no idea what Latitude or Longitude I've got to?'.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": ""
              },
              {
                "id": 11,
                "name": "Prof. Ismael Kub II",
                "businessName": "Strosin, Haley and Paucek",
                "description": "An obstacle that came between Him, and ourselves, and it. Don't let me help to undo it!' 'I shall sit here,' the Footman remarked, 'till tomorrow--' At this the whole thing very absurd, but they all stopped and looked along the course, here and there. There was no 'One, two, three, and away,' but they were all locked; and when she had not a regular rule: you invented it just missed her. Alice caught the flamingo and brought it back, the fight was over, and she was to find quite a long way.",
                "score": { "id": 1, "name": "Cintur\u00f3n Blanco", "score": 0 },
                "services": [],
                "commune": { "id": 1, "name": "Arica" },
                "smallLogo": ""
              }
            ]
          },
          {
            "title": "Porque has estado mirando la categor\u00eda Equipos y Provisiones:",
            "providers": []
          }
        ]
      })
  }
}